<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->
          <template
            v-if="$permissionAbility(USERS_DOCUMENT_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Add
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table document-table condensed"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- head -->
          <template v-if="props?.column?.field === 'format_file'">
            <template v-if="props?.row?.file">
              <b-link :href="props?.row?.file" target="_blank">
                Download File
              </b-link>
            </template>
            <template v-else>
              <span>No Files To Download</span>
            </template>
          </template>
          <!-- remarks -->
          <template v-if="props?.column?.field === 'format_remarks'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-on:click="showDescriptionModal(props.row?.remarks)"
              class="btn-sm"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <template v-if="$permissionAbility(USERS_DOCUMENT_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    variant="warning"
                    size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(USERS_DOCUMENT_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">

                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="description-modal"
      centered
      title="Remarks"
      hide-footer
      @hidden="hiddenDescriptionModal"
      no-close-on-backdrop
    >
      <b-card-text>
        {{ modalDescription }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-user-document-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Attachment Info' : 'Attach File'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="userDocumentValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- File -->
          <template v-if="modelType === 'editModel'">
            <b-form-group label="File " label-for="file">
              <validation-provider #default="{ errors }" name="file" vid="file">
                <b-form-file
                  id="file"
                  v-model="file"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <template v-else>
            <b-form-group label="File *" label-for="file">
              <validation-provider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-file
                  id="file"
                  v-model="file"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- File Type-->
          <b-form-group label="Type *" label-for="type">
            <ValidationProvider
              name="type"
              v-slot="{ errors }"
              vid="type"
              rules="required"
            >
              <b-form-input id="type" type="text" v-model="type" />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- File Remarks-->
          <b-form-group label="Remarks *" label-for="remarks">
            <ValidationProvider
              name="remarks"
              v-slot="{ errors }"
              vid="remarks"
              rules="required"
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                placeholder="Add remarks"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDocumentFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormFile,
  BFormTextarea,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_DOCUMENT_CREATE,
  USERS_DOCUMENT_EDIT,
  USERS_DOCUMENT_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DepartmentView",
  components: {
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      USERS_DOCUMENT_CREATE,
      USERS_DOCUMENT_EDIT,
      USERS_DOCUMENT_DELETE,
      modelType: "",
      file: null,
      type: "",
      remarks: "",
      documentId: "",
      modalDescription: "",

      pageLength: 10,
      columns: [
        {
          label: "File",
          field: "format_file",
          sortable: false,
        },
        {
          label: "Type",
          field: "type",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "format_remarks",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDocumentFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    // try {
    //   this.loadItems();
    // } catch (error) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Error",
    //       icon: "BellIcon",
    //       variant: "danger",
    //       text: error?.response?.data?.message,
    //     },
    //   });
    // }
  },

  methods: {
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks;
      } else {
        this.modalDescription = "N/A";
      }

      this.$bvModal.show("description-modal");
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide("description-modal");
    },
    showModal() {
      this.$bvModal.show("modal-user-document-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-document-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.file = null;
      this.type = "";
      this.remarks = "";
      this.documentId = "";
    },

    async onShow(value) {
      this.modelType = "editModel";
      this.name = value.file;
      this.type = value.type;
      this.remarks = value.remarks;
      this.documentId = value.id;
      this.showModal();
    },
    async onDelete(row) {
      const id = row.id
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${row.type}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();

            formData.append("_method", "delete");

            if (this.$route.params.id) {
              formData.append("user_id", this.$route.params.id);
            }
            await this.$api.post(`/api/user-document/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getDepartments(params) {
      return await this.$api.get("api/departments?include=head,division", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async getUserDocuments() {
      return await this.$api.get(
        `api/user-documents/user/${this.$route.params.id}`
      );
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getUserDocuments(params) {
      return await this.$api.get(
        `api/user-document/user/${this.$route.params.id}`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async loadItems() {
      try {
        const userDocuments = await this.getUserDocuments({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = userDocuments?.data?.data;
        const meta = userDocuments?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning ",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.userDocumentValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isDocumentFormSubmitLoading = true;
              const formData = new FormData();
              formData.append("_method", "PUT");
              if (this.file) {
                formData.append("file", this.file);
              }
              if (this.type) {
                formData.append("type", this.type);
              }
              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }

              if (this.$route.params.id) {
                formData.append("user_id", this.$route.params.id);
              }

              await this.$api.post(
                `/api/user-document/${this.documentId}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              this.isDocumentFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isDocumentFormSubmitLoading = true;
              const formData = new FormData();

              if (this.file) {
                formData.append("file", this.file);
              }
              if (this.type) {
                formData.append("type", this.type);
              }
              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }

              if (this.$route.params.id) {
                formData.append("user_id", this.$route.params.id);
              }

              await this.$api.post("/api/user-document", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              this.isDocumentFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isDocumentFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.userDocumentValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.document-table {
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
}

.document-table thead th,
.document-table tbody td {
  text-align: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
