<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->
          <template
              v-if="$permissionAbility(USERS_EXPERIENCE_CREATE, permissions)"
          >
            <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-on:click="showModal"
            >
              Add
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          mode="remote"
          styleClass="vgt-table bank-acc-table condensed"
          @on-sort-change="onSortChange"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <span v-if="props.column.field === 'duration'">

            {{ formatFnTableFormateDate(props?.row?.start_date) }} TO {{ formatFnTableFormateDate(props?.row?.end_date) }}

          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(USERS_EXPERIENCE_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    variant="warning"
                    size="16"
                />
              </span>
            </template>

            <template
              v-if="$permissionAbility(USERS_EXPERIENCE_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">

                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
        id="modal-user-experience-form"
        centered
        :title="
        modelType == 'editModel'
          ? 'Edit Experience'
          : 'Create Experience'
      "
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
    >
      <validation-observer ref="userExperienceValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Exam Title-->
          <b-form-group label="Company Name" label-for="company-name">
            <ValidationProvider
                name="Comapny Name"
                v-slot="{ errors }"
                vid="company_name"
                rules="required"
            >
              <b-form-input
                  id="company-name"
                  type="text"
                  v-model="company_name"
                  placeholder="Enter Company Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Institute Name -->
          <b-form-group label="Designation" label-for="designation">
            <ValidationProvider
                name="designation"
                v-slot="{ errors }"
                vid="designation"
                rules="required"
            >
              <b-form-input
                  id="designation"
                  type="text"
                  v-model="designation"
                  placeholder="Enter Designation Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Passing Year -->
          <b-form-group label="Office Address" label-for="last_address">
            <ValidationProvider
                name="office address"
                v-slot="{ errors }"
                vid="last_address"
                rules="required"
            >
              <b-form-input
                  id="last_address"
                  type="text"
                  v-model="last_address"
                  placeholder="Enter Office Address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select start date-->
          <b-form-group label="Start Date" label-for="start-date">
            <ValidationProvider
                name="start date"
                v-slot="{ errors }"
                vid="start_date"
                rules="required"
            >
              <b-form-datepicker
                  id="start-date"
                  v-model="start_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="End Date" label-for="end-date">
            <ValidationProvider
                name="end date"
                v-slot="{ errors }"
                vid="end_date"
                rules="required"
            >
              <b-form-datepicker
                  id="end-date"
                  v-model="end_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Acc Routing Number  -->
          <b-form-group
              label="Remarks"
              label-for="remarks"
          >
            <ValidationProvider
                name="remarks"
                v-slot="{ errors }"
                vid="remarks"
            >
              <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Remarks"
                  rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isUserExperienceSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormFile,
  BFormTextarea,
  BLink, BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_EXPERIENCE_CREATE,
  USERS_EXPERIENCE_EDIT,
  USERS_EXPERIENCE_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "Experience",
  components: {
    BFormDatepicker,
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      USERS_EXPERIENCE_CREATE,
      USERS_EXPERIENCE_EDIT,
      USERS_EXPERIENCE_DELETE,
      modelType: "",
      company_name: "",
      designation: "",
      start_date: "",
      end_date: "",
      last_address: "",
      remarks: "",

      pageLength: 10,
      columns: [
        {
          label: "Company Name",
          field: "company_name",
          sortable: false,
        },
        {
          label: "Designation",
          field: "designation",
          sortable: false,
        },
        {
          label: "Duration",
          field: "duration",
          sortable: false,
        },
        {
          label: "Address",
          field: "last_address",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "remarks",
          sortable: false,
        },
        {
          label: "Created At",
          field: "created_at",
          sortable: false,
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserExperienceSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    // try {
    //   this.loadItems();
    // } catch (error) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Error",
    //       icon: "BellIcon",
    //       variant: "danger",
    //       text: error?.response?.data?.message,
    //     },
    //   });
    // }
  },

  methods: {
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    formatNullableField(value) {
      if (value === null) {
        return "N/A";
      }
      return value;
    },
    showModal() {
      this.$bvModal.show("modal-user-experience-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-experience-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.company_name = "";
      this.designation = "";
      this.start_date = "";
      this.end_date = "";
      this.last_address = "";
      this.remarks = "";
    },

    async onShow(value) {
      this.modelType = "editModel";
      this.experienceId = value.id
      this.company_name = value.company_name;
      this.designation = value.designation;
      this.start_date = value.start_date;
      this.end_date = value.end_date;
      this.last_address = value.last_address;
      this.remarks = value.remarks;

      this.showModal();
    },
    async onDelete(row) {
      console.log(row)
      const id = row.id
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${row.company_name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();

            formData.append("_method", "delete")

            if (this.$route.params.id) {
              formData.append("user_id", this.$route.params.id)
            }
            await this.$api.post(`/api/user-experience/${id}`, formData)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getUserExperience(params) {
      return await this.$api.get(
          `api/user-experience/user/${this.$route.params.id}`,
          {
            params: {
              show: params.show,
              page: params.page,
              sort: params.sort,
              q: params.q,
            },
          }
      );
    },
    async loadItems() {
      try {
        const userExperience = await this.getUserExperience({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = userExperience?.data?.data;
        const meta = userExperience?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning ",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.userExperienceValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isUserExperienceSubmitLoading = true;
              await this.$api.put(`/api/user-experience/${this.experienceId}`, {
                user_id: this.$route.params.id,
                company_name: this.company_name,
                designation: this.designation,
                start_date: this.start_date,
                end_date: this.end_date,
                last_address: this.last_address,
                remarks: this.remarks
              });
              this.isUserExperienceSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isUserExperienceSubmitLoading = true;
              await this.$api.post(`/api/user-experience`, {
                user_id: this.$route.params.id,
                company_name: this.company_name,
                designation: this.designation,
                start_date: this.start_date,
                end_date: this.end_date,
                last_address: this.last_address,
                remarks: this.remarks
              });
              this.isUserExperienceSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isUserExperienceSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.userExperienceValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bank-acc-table {
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
}
.bank-acc-table thead th,
.bank-acc-table tbody td {
  text-align: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
