<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->
          <template
            v-if="$permissionAbility(USERS_BANK_ACCOUNT_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Add
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table bank-acc-table condensed"
        @on-sort-change="onSortChange"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(USERS_BANK_ACCOUNT_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    variant="warning"
                    size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(USERS_BANK_ACCOUNT_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">

                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-user-bank-account-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Bank Account Info'
          : 'Create Bank Account'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="userBankAccountValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Bank Name-->
          <b-form-group label="Bank Name *" label-for="bank-name">
            <ValidationProvider
              name="bank name"
              v-slot="{ errors }"
              vid="bank_name"
              rules="required"
            >
              <b-form-input
                id="bank-name"
                type="text"
                v-model="bankName"
                placeholder="Enter Bank Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Acc Name -->
          <b-form-group label="Account Name *" label-for="acc-name">
            <ValidationProvider
              name="account name"
              v-slot="{ errors }"
              vid="acc_name"
              rules="required"
            >
              <b-form-input
                id="acc-name"
                type="text"
                v-model="accName"
                placeholder="Enter Account Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Acc Number -->
          <b-form-group label="Account Number *" label-for="acc-number">
            <ValidationProvider
              name="account number"
              v-slot="{ errors }"
              vid="acc_number"
              rules="required"
            >
              <b-form-input
                id="acc-number"
                type="text"
                v-model="accNumber"
                placeholder="Enter Account Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- Acc Routing Number  -->
          <b-form-group
            label="Account Routing Number "
            label-for="acc-routing-number"
          >
            <ValidationProvider
              name="account routing number"
              v-slot="{ errors }"
              vid="acc_routing_number"
            >
              <b-form-input
                id="acc-routing-number"
                type="text"
                v-model="accRoutingNumber"
                placeholder="Enter Account Routing Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- File Remarks-->
          <b-form-group label="Remarks " label-for="remarks">
            <ValidationProvider
              name="remarks"
              v-slot="{ errors }"
              vid="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                placeholder="Add remarks"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isUserBankAccFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormFile,
  BFormTextarea,
  BLink, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_BANK_ACCOUNT_CREATE,
  USERS_BANK_ACCOUNT_EDIT,
  USERS_BANK_ACCOUNT_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DepartmentView",
  components: {
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      USERS_BANK_ACCOUNT_CREATE,
      USERS_BANK_ACCOUNT_EDIT,
      USERS_BANK_ACCOUNT_DELETE,
      modelType: "",
      accountId: "",
      bankName: "",
      accName: "",
      accNumber: "",
      accRoutingNumber: "",
      remarks: "",

      pageLength: 10,
      columns: [
        {
          label: "Bank Name",
          field: "bank_name",
          sortable: false,
        },
        {
          label: "Account Name",
          field: "acc_name",
          sortable: false,
        },
        {
          label: "Account Number",
          field: "acc_number",
          sortable: false,
        },
        {
          label: "Account Routing Number",
          field: "acc_routing_number",
          formatFn: this.formatNullableField,
          sortable: false,
        },
        {
          label: "Remarks",
          field: "remarks",
          formatFn: this.formatNullableField,
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserBankAccFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    // try {
    //   this.loadItems();
    // } catch (error) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Error",
    //       icon: "BellIcon",
    //       variant: "danger",
    //       text: error?.response?.data?.message,
    //     },
    //   });
    // }
  },

  methods: {
    formatNullableField(value) {
      if (value === null) {
        return "N/A";
      }
      return value;
    },
    showModal() {
      this.$bvModal.show("modal-user-bank-account-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-bank-account-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.bankName = "";
      this.accName = "";
      this.accNumber = "";
      this.accRoutingNumber = "";
      this.remarks = "";
    },

    async onShow(value) {
      this.modelType = "editModel";

      this.accountId = value.id;
      this.bankName = value.bank_name;
      this.accName = value.acc_name;
      this.accNumber = value.acc_number;
      this.accRoutingNumber = value.acc_routing_number;
      this.remarks = value.remarks;

      this.showModal();
    },
    async onDelete(row) {
      const id = row.id
      console.log(row)
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${row.acc_name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();

            formData.append("_method", "delete");

            if (this.$route.params.id) {
              formData.append("user_id", this.$route.params.id);
            }
            await this.$api.post(`/api/user-bank-account/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getUserBankAccount(params) {
      return await this.$api.get(
        `api/user-bank-account/user/${this.$route.params.id}`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async loadItems() {
      try {
        const userBankAccounts = await this.getUserBankAccount({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = userBankAccounts?.data?.data;
        const meta = userBankAccounts?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning ",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.userBankAccountValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isUserBankAccFormSubmitLoading = true;
              await this.$api.put(`/api/user-bank-account/${this.accountId}`, {
                user_id: this.$route.params.id,
                bank_name: this.bankName,
                acc_name: this.accName,
                acc_number: this.accNumber,
                acc_routing_number: this.accRoutingNumber,
                remarks: this.remarks,
              });
              this.isUserBankAccFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isUserBankAccFormSubmitLoading = true;
              await this.$api.post(`/api/user-bank-account`, {
                user_id: this.$route.params.id,
                bank_name: this.bankName,
                acc_name: this.accName,
                acc_number: this.accNumber,
                acc_routing_number: this.accRoutingNumber,
                remarks: this.remarks,
              });
              this.isUserBankAccFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isUserBankAccFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.userBankAccountValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bank-acc-table {
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
}
.bank-acc-table thead th,
.bank-acc-table tbody td {
  text-align: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
