<template>
  <b-card class="custom-padding">
    <b-row>
      <b-col cols="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <div>
                <feather-icon icon="MapPinIcon" />
                <span>Address</span>
              </div>
            </template>
            <user-edit-address-details></user-edit-address-details>
          </b-tab>

          <b-tab>
            <template #title>
              <div>
                <feather-icon icon="UsersIcon" />
                <span>Family Contact</span>
              </div>
            </template>
            <user-edit-family-contact-details></user-edit-family-contact-details>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>
    
    <script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";

import { mapGetters } from "vuex";

import UserEditAddressDetails from "./UserEditAddressDetails.vue";
import UserEditFamilyContactDetails from "./UserEditFamilyContactDetails.vue";

export default {
  name: "UserEditContactDetails",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BTabs,
    BTab,
    UserEditAddressDetails,
    UserEditFamilyContactDetails,
  },
  data() {
    return {
      userAddress: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
};
</script>
    
    <style lang="scss">
.custom-padding {
  padding: 0 0.5rem;
}
</style>
    