<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="AlertCircleIcon" />
        <span>General</span>
      </template>
      <user-edit-general-details></user-edit-general-details>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="PhoneCallIcon" />
        <span>Contact</span>
      </template>
      <user-edit-contact-details></user-edit-contact-details>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="Edit3Icon" />
        <span>Academic Qualification</span>
      </template>
      <user-edit-academic-qualification />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="BriefcaseIcon" />
        <span>Experience</span>
      </template>
      <user-edit-experience />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Documents</span>
      </template>
      <user-edit-document-details></user-edit-document-details>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="BookIcon" />
        <span>Bank Account</span>
      </template>
      <user-edit-bank-account-details></user-edit-bank-account-details>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="TrendingUpIcon" />
        <span>Employment Status</span>
      </template>
     <user-edit-designation></user-edit-designation>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="DollarSignIcon" />
        <span>Salary</span>
      </template>
     <user-edit-salary></user-edit-salary>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="DollarSignIcon" />
        <span>Employee Type</span>
      </template>
     <user-edit-emp-type></user-edit-emp-type>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" />
        <span>Security</span>
      </template>
      <user-edit-security-details></user-edit-security-details>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="ClockIcon" />
        <span>Leave Balance</span>
      </template>
      <user-leave-balance></user-leave-balance>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="Edit3Icon" />
        <span>Custom Log</span>
      </template>
      <user-add-custom-log />
    </b-tab>
  </b-tabs>
</template>

    <script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import UserEditGeneralDetails from "./UserEditGeneralDetails.vue";
import UserEditContactDetails from "./UserEditContactDetails.vue";
import UserEditDocumentDetails from "./UserEditDocumentDetails.vue";
import UserEditBankAccountDetails from "./UserEditBankAccountDetails.vue";
import UserEditExperience from "./UserEditExperience.vue";
import UserEditAcademicQualification from "./UserEditAcademicQualification.vue";
import UserEditSecurityDetails from "./UserEditSecurityDetails.vue";
import UserEditSalary from "./UserEditSalary.vue";
import UserEditEmpType from "./UserEditEmpType.vue";
import UserAddCustomLog from "./UserAddCustomLog.vue";
import UserEditDesignation from "./UserEditDesignation.vue";
import UserLeaveBalance from "./UserLeaveBalance.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    UserEditGeneralDetails,
    UserEditContactDetails,
    UserEditDocumentDetails,
    UserEditBankAccountDetails,
    UserEditExperience,
    UserEditAcademicQualification,
    UserEditSecurityDetails,
    UserEditSalary,
    UserEditEmpType,
    UserAddCustomLog,
    UserEditDesignation,
    UserLeaveBalance
  },
  data() {
    return {
      user: []
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    const user = await this.$api.get(
      `api/users/${this.$route.params.id}?include=department,roles,employeeType,designation,leaveTypes`
    )

    this.user = user?.data?.data

  },
  methods: {},
};
</script>
