<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template
            v-if="$permissionAbility(USERS_DESIGNATION_UPDATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Update
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table designation-table"
        @on-sort-change="onSortChange"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props?.column?.field === 'format_activity'">
            <span v-if="props?.row?.activity === 'initial_designation'">
              Joined As
            </span>
            <span v-if="props?.row?.activity === 'promoted'">
              Promoted To
            </span>
            <span v-if="props?.row?.activity === 'demoted'"> Demoted To </span>
            <span v-if="props?.row?.activity === 'terminated'"> Terminated </span>
          </span>

          <span v-if="props?.column?.field === 'format_value'">
            <template v-if="props?.row?.activity !== 'terminated'">
              {{ props?.row?.value }}
            </template>
          </span>

          <span v-if="props?.column?.field === 'format_remarks'">
            <template v-if="props?.row?.activity === 'terminated'">
              {{ props?.row?.value }}
            </template>
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-user-designation-form"
      centered
      title="Change Designation"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="userDesignationValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Activity Type-->
          <b-form-group label="Activity Type *" label-for="activity">
            <ValidationProvider
              name="activity"
              v-slot="{ errors }"
              vid="activity"
              rules="required"
            >
              <v-select
                id="status"
                v-model="selectActivityTypeValue"
                :options="activityTypeOptions"
                :reduce="(option) => option.value"
                label="name"
                placeholder="Choose Here"
                @input="handleActivityChange"
                class="custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group v-if="!isTerminate" label="Designation" label-for="designation-id">
            <ValidationProvider
              name="designation_id"
              v-slot="{ errors }"
              vid="designation_id"
              rules="required"
            >
              <v-select
                id="designation-id"
                placeholder="Choose a designation"
                v-model="selectDesignationId"
                :options="designationIdOptions"
                :reduce="(option) => option.id"
                label="name"
                class="custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Date" label-for="date">
            <ValidationProvider
                name="date"
                v-slot="{ errors }"
                vid="date"
                rules="required"
            >
              <b-form-datepicker
                  id="date"
                  v-model="selectDate"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  reset-button
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
              label="remarks"
              label-for="remarks"
          >
            <ValidationProvider
                name="remarks"
                v-slot="{ errors }"
                vid="remarks"
                rules=""
            >
              <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Remarks"
                  rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isUserDesignationFormSubmit">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BFormFile,
    BFormTextarea,
    BLink, BFormDatepicker,
  } from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import { designationUpdateAcitivityTypeConstants as activityTypeOptions } from "@/helpers/constant/designationUpdateAcitivityTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_DESIGNATION_UPDATE,
  USERS_DESIGNATION_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "UserDesignationView",
  components: {
    BFormDatepicker,
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      USERS_DESIGNATION_UPDATE,
      USERS_DESIGNATION_DELETE,

      selectDate: "",
      remarks: "",
      isTerminate: true,
      selectActivityTypeValue: "",
      activityTypeOptions,
      designationIdOptions: [],
      selectDesignationId: "",

      pageLength: 10,
      columns: [
        {
          label: "Activity",
          field: "format_activity",
          sortable: false,
        },
        {
          label: "Designation",
          field: "format_value",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "format_remarks",
          sortable: false,
        },
        {
          label: "Date",
          field: "effective_date",
          sortable: false,
          formatFn: this.formatFnTableLastContactDate,
        },

        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatFnTableLastContactDate,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserDesignationFormSubmit: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [designations] = await Promise.all([this.getDesignations()]);

      // designations
      this.designationIdOptions = (designations?.data?.data || []).map(
        (item) => {
          return {
            name: item?.name,
            id: item?.id,
          };
        }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    handleActivityChange(value){
      if(value !== "terminated" && value != "contact_ended"){
        this.isTerminate = false
      }else{
        this.isTerminate = true
      }
    },

    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
    async getDesignations() {
      return await this.$api.get("api/designations/all");
    },
    showModal() {
      this.$bvModal.show("modal-user-designation-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-designation-form");
      this.resetModal();
    },
    resetModal() {
      (this.selectActivityTypeValue = ""), (this.selectDesignationId = ""), (this.selectDate = ""), (this.remarks = ""), (this.isTerminate = true);
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getUserDesignation(params) {
      return await this.$api.get(
        `api/user-designation-history/user/${this.$route.params.id}`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },

    async loadItems() {
      try {
        const userDesignation = await this.getUserDesignation({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = userDesignation?.data?.data;
        const meta = userDesignation?.data?.meta;
        this.rows = data;
        this.totalRecords = meta?.pagination?.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    validationForm: async function () {
      this.$refs.userDesignationValidation.validate().then(async (success) => {
        if (success) {
          this.isUserDesignationFormSubmit = true;
          try {
            await this.$api.post(`/api/users/update-designation`, {
              user_id: this.$route.params.id,
              activity: this.selectActivityTypeValue,
              designation_id: this.selectDesignationId,
              effective_date: this.selectDate,
              remarks: this.remarks
            });
            this.isUserDesignationFormSubmit = false;
            this.hiddenModal();
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Updated",
              },
            });
          } catch (error) {
            this.isUserDesignationFormSubmit = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.userDesignationValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
.designation-table {
  min-height: 137px !important;

  font-size: 13px !important;
}

.designation-table thead th,
.designation-table tbody td {
  text-align: center;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
