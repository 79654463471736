<template>
  <div class="col-12">
    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
            :rows="rows"
            :columns="columns"
            :sort-options="{
          enabled: false,
          multipleColumns: true,
        }"
            :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"

            styleClass="vgt-table striped"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: eligible_employee_types -->
            <div v-if="props.column.field === 'type'">
              <template v-if="props.row?.name">
                {{ props.row?.name }}
              </template>
            </div>

            <div v-if="props.column.field === 'leave_allowance'">
              <template v-if="props.row?.leave_allowance">
                <b-badge class="ml-1" variant="light-primary">
                  {{ props.row?.leave_allowance }}
                </b-badge>
              </template>
            </div>

            <div v-if="props.column.field === 'owned'">
              <b-badge class="ml-1" variant="light-danger">
                <template v-if="props.row?.leave_activity">
                  {{ props.row?.leave_activity?.leave_owned }}
                </template>
                <template v-else>
                  0
                </template>
              </b-badge>
            </div>

            <div v-if="props.column.field === 'format_remaining'">
              <b-badge class="ml-1" variant="light-danger">
                <template v-if="props.row?.leave_activity">
                  {{ (props.row?.leave_allowance - props.row?.leave_activity?.leave_owned) <= 0 ? 0 : props.row?.leave_allowance - props.row?.leave_activity?.leave_owned }}
                </template>
                <template v-else>
                  {{ props.row?.leave_allowance }}
                </template>
              </b-badge>
            </div>

          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, min } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  LEAVE_TYPES_CREATE,
  LEAVE_TYPES_EDIT,
  LEAVE_TYPES_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "UserLeaveBalance",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LEAVE_TYPES_CREATE,
      LEAVE_TYPES_EDIT,
      LEAVE_TYPES_DELETE,

      pageLength: 10,
      columns: [
        {
          label: "Type",
          field: "type",
          sortable: false,
        },
        {
          label: "Allowance",
          field: "leave_allowance",
          sortable: false,
        },
        {
          label: "Availed",
          field: "owned",
          sortable: false,
        },
        {
          label: "Remaining",
          field: "format_remaining",
          sortable: false,
        },
      ],
      rows: [],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      await Promise.all([this.loadItems()]);
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getLeaveTypes(yearValue) {
      return await this.$api.get("api/leave/balance?user_id=" + this.$route.params.id);
    },

    async loadItems() {
      try {
        const [leaveTypes] = await Promise.all([
          this.getLeaveTypes(),
        ]);

        const data = leaveTypes?.data?.data;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
