var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "styleClass": "vgt-table striped"
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row6$leave_act, _props$row7, _props$row8, _props$row9, _props$row9$leave_act, _props$row10, _props$row11, _props$row11$leave_ac, _props$row12;
        return [props.column.field === 'type' ? _c('div', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.name ? [_vm._v(" " + _vm._s((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.name) + " ")] : _vm._e()], 2) : _vm._e(), props.column.field === 'leave_allowance' ? _c('div', [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.leave_allowance ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.leave_allowance) + " ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'owned' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-danger"
          }
        }, [(_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.leave_activity ? [_vm._v(" " + _vm._s((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$leave_act = _props$row6.leave_activity) === null || _props$row6$leave_act === void 0 ? void 0 : _props$row6$leave_act.leave_owned) + " ")] : [_vm._v(" 0 ")]], 2)], 1) : _vm._e(), props.column.field === 'format_remaining' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-danger"
          }
        }, [(_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.leave_activity ? [_vm._v(" " + _vm._s(((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.leave_allowance) - ((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : (_props$row9$leave_act = _props$row9.leave_activity) === null || _props$row9$leave_act === void 0 ? void 0 : _props$row9$leave_act.leave_owned) <= 0 ? 0 : ((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.leave_allowance) - ((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : (_props$row11$leave_ac = _props$row11.leave_activity) === null || _props$row11$leave_ac === void 0 ? void 0 : _props$row11$leave_ac.leave_owned)) + " ")] : [_vm._v(" " + _vm._s((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.leave_allowance) + " ")]], 2)], 1) : _vm._e()];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }