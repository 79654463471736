var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "custom-padding"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', [_c('feather-icon', {
          attrs: {
            "icon": "MapPinIcon"
          }
        }), _c('span', [_vm._v("Address")])], 1)];
      },
      proxy: true
    }])
  }, [_c('user-edit-address-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        }), _c('span', [_vm._v("Family Contact")])], 1)];
      },
      proxy: true
    }])
  }, [_c('user-edit-family-contact-details')], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }