var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AlertCircleIcon"
          }
        }), _c('span', [_vm._v("General")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-general-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PhoneCallIcon"
          }
        }), _c('span', [_vm._v("Contact")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-contact-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon"
          }
        }), _c('span', [_vm._v("Academic Qualification")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-academic-qualification')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "BriefcaseIcon"
          }
        }), _c('span', [_vm._v("Experience")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-experience')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v("Documents")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-document-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "BookIcon"
          }
        }), _c('span', [_vm._v("Bank Account")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-bank-account-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "TrendingUpIcon"
          }
        }), _c('span', [_vm._v("Employment Status")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-designation')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        }), _c('span', [_vm._v("Salary")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-salary')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        }), _c('span', [_vm._v("Employee Type")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-emp-type')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "LockIcon"
          }
        }), _c('span', [_vm._v("Security")])];
      },
      proxy: true
    }])
  }, [_c('user-edit-security-details')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon"
          }
        }), _c('span', [_vm._v("Leave Balance")])];
      },
      proxy: true
    }])
  }, [_c('user-leave-balance')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon"
          }
        }), _c('span', [_vm._v("Custom Log")])];
      },
      proxy: true
    }])
  }, [_c('user-add-custom-log')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }