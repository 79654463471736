<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <validation-observer ref="addressValidation">
            <b-form v-on:submit.prevent="validationForm" class="px-2">
              <b-row>
                <b-col md="6" lg="6" xs="6">
                  <b-row>
                    <b-col md="12" lg="12" xs="12">
                      <h5>Present Address</h5></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12" lg="12" xs="12">
                      <b-form-group
                        label="Address *"
                        label-for="present-address"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="present address"
                          vid="present-address"
                          rules="required"
                        >
                          <b-form-textarea
                            id="present-address"
                            v-model="presentAddress"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Present Address"
                            :disabled="!isUserAddressFormEdit"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" lg="6" xs="12">
                      <!-- name  -->
                      <b-form-group
                        label="Country *"
                        label-for="present-country"
                      >
                        <ValidationProvider
                          name="present country name"
                          v-slot="{ errors }"
                          vid="present-country"
                          rules="required"
                        >
                          <v-select
                            id="present-country"
                            placeholder="Choose here"
                            v-model="presentCountry"
                            :options="countryIdOption"
                            :reduce="(country) => country.id"
                            label="name"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- city -->
                      <b-form-group label="City *" label-for="present-city">
                        <validation-provider
                          #default="{ errors }"
                          name="present city"
                          vid="present-city"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="present-city"
                            type="text"
                            v-model="presentCity"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter City Name"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- state -->
                    <b-col md="6" lg="6" xs="12">
                      <b-form-group label="State *" label-for="present-state">
                        <validation-provider
                          #default="{ errors }"
                          name="present state"
                          vid="present-state"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="present-state"
                            type="text"
                            v-model="presentState"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter State Name"
                            rules="required|max:255"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" lg="6" xs="12">
                      <!-- street -->
                      <b-form-group label="Street *" label-for="present-street">
                        <validation-provider
                          #default="{ errors }"
                          name="present street"
                          vid="present-street"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="present-street"
                            type="text"
                            v-model="presentStreet"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Street Name"
                            rules="required|max:255"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- zip code -->
                      <b-form-group
                        label="Post Office *"
                        label-for="present-post-office"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="present post office"
                          vid="present-post-office"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="present-post-office"
                            type="text"
                            v-model="presentPostOffice"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Post Office"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- zip code -->
                      <b-form-group
                        label="Zip Code *"
                        label-for="present-zip-code"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="present zip code"
                          vid="present-zip-code"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="present-zip-code"
                            type="text"
                            v-model="presentZipcode"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Zip Code"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6" lg="6" xs="6">
                  <b-row>
                    <b-col md="12" lg="12" xs="12">
                      <h5>Permanent Address</h5></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12" lg="12" xs="12">
                      <b-form-group
                        label="Address *"
                        label-for="permanent-address"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=" permanent address"
                          vid="permanent-address"
                          rules="required"
                        >
                          <b-form-textarea
                            id="permanent-address"
                            v-model="permanentAddress"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Permanent Address"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" lg="6" xs="12">
                      <!-- name  -->
                      <b-form-group
                        label="Country *"
                        label-for="permanent-country"
                      >
                        <ValidationProvider
                          name="permanent country name"
                          v-slot="{ errors }"
                          vid="permanent-country"
                          rules="required"
                        >
                          <v-select
                            id="permanent-country"
                            placeholder="Choose here"
                            v-model="permanentCountry"
                            :options="countryIdOption"
                            :reduce="(country) => country.id"
                            label="name"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- city -->
                      <b-form-group label="City *" label-for="permanent-city">
                        <validation-provider
                          #default="{ errors }"
                          name="permanent city"
                          vid="permanent-city"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="permanent-city"
                            type="text"
                            v-model="permanentCity"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter City Name"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- state -->
                    <b-col md="6" lg="6" xs="12">
                      <b-form-group label="State *" label-for="permanent-state">
                        <validation-provider
                          #default="{ errors }"
                          name="permanent state"
                          vid="permanent-state"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="permanent-state"
                            type="text"
                            v-model="permanentState"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter State Name"
                            rules="required|max:255"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" lg="6" xs="12">
                      <!-- street -->
                      <b-form-group
                        label="Street *"
                        label-for="permanent-street"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="permanent street"
                          vid="permanent-street"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="permanent-street"
                            type="text"
                            v-model="permanentStreet"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Street Name"
                            rules="required|max:255"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- zip code -->
                      <b-form-group
                        label="Post Office *"
                        label-for="permanent-post-office"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="present post office"
                          vid="permanent-post-office"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="permanent-post-office"
                            type="text"
                            v-model="permanentPostOffice"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Post Office "
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6" xs="12">
                      <!-- zip code -->
                      <b-form-group
                        label="Zip Code *"
                        label-for="permanent-zip-code"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=" permanent zip code"
                          vid="permanent-zip-code"
                          rules="required|max:255"
                        >
                          <b-form-input
                            id="permanent-zip-code"
                            type="text"
                            v-model="permanentZipcode"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Zip Code"
                            :disabled="!isUserAddressFormEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- <template v-if="isAddressFormSubmitLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              submit button
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Save
                </b-button>
              </template> -->
              <b-row>
                <b-col cols="12">
                  <!-- Edit & Cancel button -->
                  <template
                    v-if="
                      $permissionAbility(USERS_CONTACT_INFO_EDIT, permissions)
                    "
                  >
                    <b-button
                      class="float-right"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      v-on:click="enableEditingFields"
                      id="editButton"
                    >
                      <template v-if="!isUserAddressFormEdit"> Edit </template>
                      <template v-else-if="isUserAddressFormEdit">
                        Cancel
                      </template>
                    </b-button>
                  </template>
                  <!-- loading button -->
                  <template v-if="isUserAddressFormEdit">
                    <b-button
                      type="submit"
                      class="float-right mr-2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                    >
                      Save
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import { USERS_CONTACT_INFO_EDIT } from "@/helpers/permissionsConstant";
import { heightTransition } from "@core/mixins/ui/transition";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "UserEditAddressDetails",

  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      USERS_CONTACT_INFO_EDIT,
      userAddress: "",
      presentAddress: "",
      presentCountry: "",
      presentState: "",
      presentStreet: "",
      presentCity: "",
      presentPostOffice: "",
      presentZipcode: "",
      permanentAddress: "",
      permanentCountry: "",
      permanentState: "",
      permanentCity: "",
      permanentStreet: "",
      permanentPostOffice: "",
      permanentZipcode: "",
      countryIdOption: [],
      isUserAddressFormEdit: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
  async created() {
    try {
      const [countries, userData] = await Promise.all([
        this.getCountries(),
        this.getUserAddress(),
      ]);
      this.userAddress = userData?.data?.data?.userAddress?.data;
      this.loadUserAddress(this.userAddress);

      //country
      this.countryIdOption = (countries?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    enableEditingFields() {
      if (this.isUserAddressFormEdit) {
        this.isUserAddressFormEdit = false;
      } else {
        this.isUserAddressFormEdit = true;
      }
    },
    async getCountries() {
      return await this.$api.get("api/country/all");
    },
    async getUserAddress() {
      return await this.$api.get(
        `api/users/${this.$route.params.id}?include=userAddress`
      );
    },

    loadUserAddress(params) {
      this.presentAddress = params?.present_address;
      this.presentCountry = params?.present_country_id;
      this.presentCity = params?.present_city;
      this.presentState = params?.present_state;
      this.presentStreet = params?.present_street;
      this.presentPostOffice = params?.present_post_office;
      this.presentZipcode = params?.present_zipcode;
      this.permanentAddress = params?.permanent_address;
      this.permanentCountry = params?.permanent_country_id;
      this.permanentCity = params?.permanent_city;
      this.permanentState = params?.permanent_state;
      this.permanentStreet = params?.permanent_street;
      this.permanentPostOffice = params?.permanent_post_office;
      this.permanentZipcode = params?.permanent_zipcode;
    },

    validationForm: async function () {
      this.$refs.addressValidation.validate().then(async (success) => {
        if (success) {
          try {
            await this.$api.post("/api/user-address", {
              user_id: this.$route.params.id,
              present_address: this.presentAddress,
              present_country_id: this.presentCountry,
              present_city: this.presentCity,
              present_state: this.presentState,
              present_street: this.presentStreet,
              present_city: this.presentCity,
              present_post_office: this.presentPostOffice,
              present_zipcode: this.presentZipcode,
              permanent_address: this.permanentAddress,
              permanent_country_id: this.permanentCountry,
              permanent_city: this.permanentCity,
              permanent_state: this.permanentState,
              permanent_street: this.permanentStreet,
              permanent_city: this.permanentCity,
              permanent_post_office: this.permanentPostOffice,
              permanent_zipcode: this.permanentZipcode,
            });
            this.isUserAddressFormEdit = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Address Information Saved",
              },
            });
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.addressValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>