var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "profilePasswordValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Old Password",
      "label-for": "old-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "old password",
      "rules": "required|min:6",
      "vid": "old_password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "old-password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldTypeOld,
            "name": "old_password",
            "placeholder": "············"
          },
          model: {
            value: _vm.old_password,
            callback: function callback($$v) {
              _vm.old_password = $$v;
            },
            expression: "old_password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": _vm.togglePasswordOld
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconOld
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": "required|min:6",
      "vid": "password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldTypeNew,
            "name": "password",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": _vm.togglePasswordNew
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconNew
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "password_confirmation"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password confirmation",
      "rules": "required|confirmed:password",
      "vid": "password_confirmation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password_confirmation",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldTypeRetype,
            "name": "password_confirmation",
            "placeholder": "············"
          },
          model: {
            value: _vm.password_confirmation,
            callback: function callback($$v) {
              _vm.password_confirmation = $$v;
            },
            expression: "password_confirmation"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": _vm.togglePasswordRetype
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconRetype
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.isProfilePasswordFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_vm.$permissionAbility(_vm.USERS_PASSWORD_UPDATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Change ")])] : _vm._e()]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }